<template>
  <div class="home container" id="home-page">
    <div class="home__info">
      <h1 class="home__title">CHARLES VENEAU</h1>
      <p class="home__text">web<span class="text__orange">_</span>dev</p>
    </div>
    <div class="home__img" id="scene">
      <img
        data-depth="0.3"
        src="@/assets/images/charles6.png"
        alt=""
        id="photo"
      />
    </div>
    <router-link to="/about" class="chevron-down">
      <BaseIcon name="chevron-down"></BaseIcon>
    </router-link>
  </div>
</template>
<script>
export default {
  metaInfo() {
    return {
      title: `Charles Veneau`,
      titleTemplate: null,
      meta: [
        {
          name: "Home",
          content: `Charles Veneau, développeur Web Vue.js disponible en région parisienne et Lyon. Création d'applications web, ou de sites Wordpress.`
        }
      ]
    };
  },
  mounted() {
    const home = document.getElementById("home-page");
    const photo = document.getElementById("photo");
    if (window.innerWidth > 1000) {
      home.addEventListener("mousemove", () => {
        let X = event.clientX - window.innerWidth / 2;
        photo.style.transform = `translate3D(${-X / 30}px, 0, 0)`;
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.menu-icon {
  display: none;
}
</style>
