<template>
  <div class="menu-structure">
    <BaseIcon name="menu" class="open-icon" @click.native="toggleMenu" />
    <nav id="menu-elt" class="hidden">
      <BaseIcon name="x" class="close-icon" @click.native="toggleMenu" />
      <div class="menu-list">
        <router-link
          class="menu-link"
          :to="{ name: 'Home' }"
          @click.native="toggleMenu"
          >Accueil</router-link
        >
        <router-link
          class="menu-link"
          :to="{ name: 'About' }"
          @click.native="toggleMenu"
          >A Propos</router-link
        >
        <router-link
          class="menu-link"
          :to="{ name: 'Portfolio' }"
          @click.native="toggleMenu"
          >Portfolio</router-link
        >
        <router-link
          class="menu-link"
          :to="{ name: 'Contact' }"
          @click.native="toggleMenu"
          >Contact</router-link
        >
      </div>
    </nav>
  </div>
</template>

<script>
export default {
  data() {
    return {
      menuIsOpen: true
    };
  },
  methods: {
    toggleMenu() {
      let menuIsOpen = document.getElementById("menu-elt");
      menuIsOpen.classList.toggle("hidden");
    }
  }
};
</script>

<style lang="scss" scoped></style>
