<template>
  <div id="app">
    <transition name="slide-fade" mode="out-in">
      <router-view />
    </transition>
  </div>
</template>

<script>
import _ from "lodash";

export default {
  metaInfo() {
    return {
      title: `Portfolio`,
      titleTemplate: " %s | Charles Veneau",
      meta: [
        {
          name: "App"
        }
      ]
    };
  },
  mounted() {
    let self = this;
    window.addEventListener(
      "wheel",
      _.throttle(function($event) {
        const route = self.$router.history.current.name;
        if ($event.deltaY > 0) {
          switch (route) {
            case "Home":
              self.$router.push("/about");
              break;
          }
        } else {
          switch (route) {
            case "About":
              self.$router.push("/");
              break;
          }
        }
      }, 2000)
    );
    window.addEventListener("scroll", () => {
      if (this.$router.history.current.name == "Home") {
        this.$router.push("/about");
      }
    });
  }
};
</script>
