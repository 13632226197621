import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    projects: [
      {
        id: "1",
        title: "Matériauthèque",
        content: "Maquette site web - en cours 2020",
        techno: "Adobe XD | Wordpress",
        caseStudy:
          "Le client veut créer un site de référence sur les matériaux de construction. Il veut ainsi pour gérer lui même le contenu, les commentaires et toute la vie du site (SEO, Newsletter). Après plusieurs échanges et propositions de design. L'intégration du site s'est fait sur Wordpress afin d'avoir un support solide pour le backend. Le projet est en cours de finalisation.",
        image: require("@/assets/images/Materiautheque.png")
      },
      {
        id: "2",
        title: "Forecast App",
        content: "Météo avec API - Juin 2020",
        techno: "Vue.js | OpenWeather API",
        caseStudy:
          "Dans la continuité de mon apprentissage de Vue.js, j'ai voulu créer un petit module météo avec des appels API afin de tester le potentiel du frameword Vue.js.",
        lien: "https://charlesveneau.github.io/forecast-app/#/",
        image: require("@/assets/images/foreCastApp.jpg")
      },
      {
        id: "3",
        title: "todoList",
        content: "Firebase todo list - Mai 2020",
        techno: "Vue.js | Google Firebase",
        caseStudy:
          "Je souhaitais apporter des améliorations à un exercice de todo list fait lors d'une formation. J'ai d'abord porter le frontend sur Vue.js afin de travailler mes acquis, puis j'ai intégré Firebase afin de gérer le contenu et l'authentification.",
        lien: "http://todolist.charlesveneau.fr/",
        image: require("@/assets/images/todoList.jpg")
      },
      {
        id: "4",
        title: "Cabinet Roland Garros",
        content: "Wordpress - Mai 2019",
        techno: "Adobe XD | Wordpress",
        caseStudy:
          "Mes clientes avaient besoin d'un site vitrine pour leur cabinet de psychologie. La navigation devait être simple et intuitive, et ne pas surcharger le contenu. Un module de prise de rendez-vous a été implementé avec un plugin Wordpress. Le design reste simple et moderne.",
        lien: "https://cabinetrolandgarros.fr/",
        image: require("@/assets/images/cabinetRolandGarros.jpg")
      }
    ]
  },
  mutations: {},
  actions: {},
  modules: {}
});
